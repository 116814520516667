















































import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
export default Vue.extend({
  props: {
    leadUuid: String,
    leadUuids: [],
    color: String,
  },
  name: "crm-asign-to",
  data() {
    return {
      dialog: false,
      asignTo: "",
    };
  },
  computed: {
    ...mapGetters(["getCoordinatorN"]),
    ...mapState("crmEmployeeModule", ["agents"]),
  },
  methods: {
    ...mapActions("crmEmployeeModule", ["actGetPatientAgents"]),
    ...mapActions("crmConfigurationsModule", [
      "actAsignToLead",
      "actMultiAsignToLead",
    ]),

    cancel() {
      this.dialog = false;
      this.asignTo = "";
    },
    async confirm() {
      if (this.leadUuids == undefined) {
        await this.actAsignToLead({
          leadUuid: this.leadUuid,
          asingTo: this.asignTo,
        });
      } else {
        await this.actMultiAsignToLead({
          asingTo: this.asignTo,
          items: this.leadUuids,
        });
      }

      this.$emit("assigned");
      this.cancel();
    },
  },
  mounted() {
    this.actGetPatientAgents();
  },
});
