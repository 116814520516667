<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <!--  Edit Lead -->
  <v-dialog
    v-model="dialogEdit"
    persistent
    :overlay="false"
    max-width="850"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" small icon color="primary"
        ><v-icon small>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-icon>mdi-pencil</v-icon> Edit
      </v-toolbar>
      <v-card-text class="mt-3">
        <v-form v-model="formValid">
          <v-container>
            <div class="row">
              <div class="col-sm-6">
                <v-text-field
                  label="Name *"
                  outlined
                  class="rounded"
                  dense
                  :rules="[rules.required]"
                  v-model="itemEdit.name"
                ></v-text-field>
              </div>
              <div class="col-sm-6">
                <v-text-field
                  label="Last Name *"
                  class="rounded"
                  dense
                  :rules="[rules.required]"
                  v-model="itemEdit.last_name"
                  outlined
                ></v-text-field>
              </div>
            </div>
            <v-row class="texts1">
              <v-col cols="12" sm="4">
                <template v-if="!editDOB">
                  <v-text-field
                    outlined
                    dense
                    readonly
                    label="DOB"
                    v-model="dobShow"
                  >
                    <template slot="append">
                      <v-icon @click="editDOB = true" color="info"
                        >mdi-pencil</v-icon
                      >
                    </template>
                  </v-text-field>
                </template>
                <template v-else>
                  <ma-date-picker
                    v-model="itemEdit.birthDate"
                    past
                    label="DOB"
                    :editable="true"
                  />
                </template>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="itemEdit.weight"
                  label="Weight (lb)"
                  outlined
                  dense
              /></v-col>
              <v-col cols="12" sm="4"
                ><v-text-field
                  v-model="itemEdit.height"
                  label="Height (ft,in)"
                  outlined
                  dense
              /></v-col>
            </v-row>

            <div class="row texts1">
              <div class="col-sm-6">
                <v-text-field
                  label="Email *"
                  class="rounded"
                  dense
                  :rules="[rules.required, rules.email]"
                  v-model="itemEdit.email"
                  outlined
                ></v-text-field>
              </div>
              <div class="col-sm-6">
                <v-text-field
                  label="Phone *"
                  class="rounded"
                  prepend-inner-icon="mdi-numeric-positive-1"
                  autocomplete="tel"
                  dense
                  :rules="[rules.phone]"
                  v-model="itemEdit.phone"
                  outlined
                ></v-text-field>
              </div>
            </div>
            <div class="row texts1">
              <div class="col-sm-4">
                <v-autocomplete
                  v-model="itemEdit.howKnow"
                  :loading="loadingRefer"
                  :items="references"
                  :rules="[rules.required]"
                  item-text="name"
                  item-value="name"
                  label="How do finded us *"
                  outlined
                  dense
                />
              </div>
              <div class="col-sm-4">
                <v-autocomplete
                  v-model="itemEdit.status"
                  :items="status"
                  item-text="name"
                  item-value="value"
                  label="Status"
                  outlined
                  dense
                />
              </div>
              <div class="col-sm-4 mb-1">
                <v-layout class="mb-4" align-center>
                  <label class="mr-3"><strong>Gender:</strong> </label>
                  <v-radio-group
                    v-model="gender"
                    :rules="[rules.required]"
                    class="mt-0"
                    mandatory
                    row
                    dense
                    hide-details
                    @change="itemEdit.isMale = $event == 'male' ? true : false"
                  >
                    <v-radio value="male" :label="$t('labelMale')" />
                    <v-radio
                      value="female"
                      :label="$t('labelFemale')"
                    ></v-radio>
                  </v-radio-group>
                </v-layout>
              </div>
            </div>
            <v-divider class="mb-1 texts2 mx-2"></v-divider>
            <div class="d-flex justify-center text-center">
              <h4>Medical History</h4>
            </div>
            <v-divider class="my-1 mx-2"></v-divider>
            <v-row class="mx-1">
              <v-col cols="12" sm="6">
                <div class="d-flex">
                  <v-select
                    class="mr-1"
                    :items="types"
                    v-model="typeProcedure"
                    item-text="label"
                    item-value="value"
                    outlined
                    dense
                    label="Type"
                  ></v-select>

                  <v-select
                    :items="subtypes"
                    v-model="procedureInterest"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    label="Procedure"
                  ></v-select></div
              ></v-col>
              <v-col cols="12" sm="6">
                <v-select
                  :loading="loading"
                  :items="doctors"
                  v-model="itemEdit.medicalHistory.doctorChoiceUuid"
                  item-text="fullname"
                  item-value="uuid"
                  outlined
                  label="Doctor of choice"
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-divider class="my-1 texts mx-2"></v-divider>
            <v-row class="mx-1">
              <v-col cols="12" sm="6">
                <v-textarea
                  auto-grow
                  v-model="itemEdit.medicalHistory.medicalCondition"
                  rows="1"
                  row-height="65"
                  outlined
                  dense
                  label="Medical Condition"
                ></v-textarea>
              </v-col>

              <v-col cols="12" sm="6">
                <v-textarea
                  auto-grow
                  v-model="itemEdit.medicalHistory.alergies"
                  rows="1"
                  row-height="65"
                  outlined
                  dense
                  label="Alergies"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-divider class="my-1 texts mx-2"></v-divider>
            <v-row class="mx-1">
              <v-col cols="12" sm="6">
                <v-textarea
                  auto-grow
                  v-model="itemEdit.medicalHistory.previosSurgery"
                  rows="1"
                  row-height="65"
                  outlined
                  dense
                  label="Previous surgeries"
                ></v-textarea>
              </v-col>

              <v-col cols="12" sm="6">
                <v-textarea
                  auto-grow
                  v-model="itemEdit.medicalHistory.currentMedications"
                  rows="1"
                  row-height="65"
                  outlined
                  dense
                  label="Current medications"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-divider class="my-1 texts mx-2"></v-divider>
            <v-row class="mx-1 texts">
              <v-col cols="12" sm="4">
                <v-switch
                  v-model="itemEdit.medicalHistory.smoking"
                  inset
                  label="Smoking"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="4">
                <v-switch
                  v-model="itemEdit.medicalHistory.alcohol"
                  inset
                  label="Alcohol"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="4">
                <v-switch
                  v-model="othersubstances"
                  inset
                  label="Other Substances"
                ></v-switch>
              </v-col>
            </v-row>
            <template v-if="othersubstances">
              <v-row class="mx-1 texts2">
                <v-col cols="12" sm="12">
                  <v-textarea
                    auto-grow
                    v-model="itemEdit.medicalHistory.otherSubstances"
                    rows="1"
                    row-height="45"
                    outlined
                    dense
                    label="Substances"
                  ></v-textarea>
                </v-col>
              </v-row>
            </template>
            <v-row class="mx-1 texts2">
              <v-col cols="12" sm="12">
                <v-textarea
                  auto-grow
                  v-model="itemEdit.medicalHistory.details"
                  rows="1"
                  row-height="65"
                  outlined
                  dense
                  label="Notes"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="actions">
        <v-spacer></v-spacer>
        <v-btn text @click="cancelEdit" color="error">Cancel</v-btn>
        <v-btn
          text
          :disabled="!formValid"
          :loading="loadingAcciones"
          @click="confirmEdit"
          color="primary"
          >Edit</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import rules from "@/components/account/rules";
import { mapState } from "vuex";
import { getAPI } from "@/api/axios-base";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import formatPhone from "@/utils/formatPhone";
import { notifyError, notifySuccess } from "@/components/Notification";
import moment from "moment";
export default {
  name: "edit-lead",
  components: { MaDatePicker },
  props: {
    item: Object,
    treatments: Array,
    surgerys: Array,
  },
  data() {
    return {
      editDOB: false,
      loadingAcciones: false,
      gender: "male",
      rules: {
        email: rules.email,
        required: rules.required,
        phone: rules.isPhone,
        maxCharsfull: (v) => v.length >= 3 || this.$t("min3Chars"),
      },

      status: [
        { name: "Callback", value: "Callback" },
        { name: "Text", value: "Text" },
        { name: "Interested", value: "Interested" },
        { name: "Not Interested", value: "NotInterested" },
        { name: "Wrong Number", value: "WrongNumber" },
        { name: "Pending", value: "Pending" },
      ],
      formValid: false,
      loadingProcedures: false,

      othersubstances: false,
      typeProcedure: "Surgery",
      procedureInterest: null,

      dialogEdit: false,

      itemEdit: {
        leadUuid: "",
        status: null,
        howKnow: "",
        name: "",
        last_name: "",
        phone: "",
        email: "",
        weight: 0,
        height: 0,
        isMale: false,
        birthDate: "",
        medicalHistory: {
          surgeryTypeId: null,
          treatmentTypeId: null,
          doctorChoiceUuid: null,
          smoking: false,
          alcohol: false,
          otherSubstances: "",
          medicalCondition: "",
          alergies: "",
          previosSurgery: "",
          currentMedications: "",
          details: "",
        },
      },
    };
  },
  computed: {
    ...mapState("crmSettingsModule", ["loadingRefer", "references"]),
    ...mapState("crmEmployeeModule", ["coordinators", "doctors", "loading"]),
    dateSend() {
      if (
        this.itemEdit.birthDate == null ||
        this.itemEdit.birthDate == undefined
      ) {
        return "";
      }
      const f = moment(this.itemEdit.birthDate).format("YYYY-DD-MM");
      return f;
    },

    types() {
      return [
        { label: "Surgery", value: "Surgery" },
        { label: "Treatment", value: "Treatment" },
      ];
    },
    subtypes() {
      switch (this.typeProcedure) {
        case "Treatment":
          return this.treatments;
        default:
          return this.surgerys;
      }
    },
    dobShow() {
      if (
        this.itemEdit.birthDate == null ||
        this.itemEdit.birthDate == undefined
      ) {
        return "";
      }
      const f = moment(this.itemEdit.birthDate).format("MM-DD-YYYY");
      return f;
    },
  },
  mounted() {
    if (this.item) {
      let pho = this.item.phone;
      if (pho.includes("+1")) {
        pho = pho.replace("+1", "");
      }
      pho = pho.replaceAll("\t", "");
      pho = pho.replaceAll(" ", "");
      pho = formatPhone(pho);
      if (
        this.item.medicalHistory != null &&
        this.item.medicalHistory.otherSubstances
      ) {
        this.othersubstances = true;
      }
      if (
        this.item.medicalHistory != null &&
        this.item.medicalHistory.surgeryType
      ) {
        this.procedureInterest = this.item.medicalHistory.surgeryType.id;
        this.typeProcedure = "Surgery";
      } else if (
        this.item.medicalHistory != null &&
        this.item.medicalHistory.treatmentType
      ) {
        this.procedureInterest = this.item.medicalHistory.treatmentType.id;
        this.typeProcedure = "Treatment";
      } else {
        this.procedureInterest = null;
        this.typeProcedure = "Surgery";
      }

      this.itemEdit = {
        leadUuid: this.item.uuid,
        name: this.item.name,
        last_name: this.item.last_name,
        phone: pho,
        email: this.item.email,
        howKnow: this.item.howKnow,
        status: this.item.status,
        weight: this.item.weight,
        height: this.item.height,
        birthDate: this.item.birthDate,
        isMale: this.item.isMale,
        medicalHistory:
          this.item.medicalHistory != null
            ? {
                surgeryTypeId: null,
                treatmentTypeId: null,
                doctorChoiceUuid: this.item.medicalHistory.doctorChoice
                  ? this.item.medicalHistory.doctorChoice.uuid
                  : null,
                smoking: this.item.medicalHistory.smoking,
                alcohol: this.item.medicalHistory.alcohol,
                otherSubstances: this.item.medicalHistory.otherSubstances,
                medicalCondition: this.item.medicalHistory.medicalCondition,
                alergies: this.item.medicalHistory.alergies,
                previosSurgery: this.item.medicalHistory.previosSurgery,
                currentMedications: this.item.medicalHistory.currentMedications,
                details: this.item.medicalHistory.details,
              }
            : {
                surgeryTypeId: null,
                treatmentTypeId: null,
                doctorChoiceUuid: null,
                smoking: false,
                alcohol: false,
                otherSubstances: "",
                medicalCondition: "",
                alergies: "",
                previosSurgery: "",
                currentMedications: "",
                details: "",
              },
      };

      this.gender = this.itemEdit.isMale ? "male" : "female";
    }
  },
  methods: {
    cancelEdit() {
      this.dialogEdit = false;
      this.editDOB = false;
    },
    confirmEdit() {
      this.loadingAcciones = true;
      const body = Object.assign({}, this.itemEdit);
      body.phone = "+1" + body.phone;
      if (this.typeProcedure == "Surgery") {
        body.medicalHistory.surgeryTypeId = this.procedureInterest;
      } else {
        body.medicalHistory.treatmentTypeId = this.procedureInterest;
      }
      body.weight =
        body.weight != null && body.weight != "" ? Number(body.weight) : 0;
      body.height =
        body.height != null && body.height != "" ? Number(body.height) : 0;
      getAPI
        .put("/social/updateCounts", body)
        .then(() => {
          notifySuccess("Lead has been Edited");
          this.loadingAcciones = false;
          this.cancelEdit();
          this.$emit("edited");
        })
        .catch((error) => {
          this.loadingAcciones = false;
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.cardexpand {
  width: 100vh !important;
}
.texts {
  margin-top: -26px !important;
  margin-bottom: 10px !important;
}
.texts1 {
  margin-top: -40px !important;
  margin-bottom: 10px !important;
}
.texts2 {
  margin-top: -40px !important;
  margin-bottom: 8px !important;
}
.actions {
  margin-top: -50px !important;
}
.texts3 {
  margin-top: -40px !important;
  margin-bottom: 5px !important;
}
</style>
