/* eslint-disable prettier/prettier */
import Vue from "vue";
import longTime from "@/utils/longTime";

const MessageMixin = Vue.extend({
  props: {
    first: Boolean,
    last: Boolean,
    middle: Boolean,
    uuid: String,
    from: String,
    to: String,
    createAt: String,
    twilioStatus: String,
    message: String,
    mediasUrl: Array,
    messagingServiceSid: String,
    twilioSid: String,
    status: String,
    replyToSmsUuid: String,
  },
  methods: {
    longTime,
  },
});

export default MessageMixin;
