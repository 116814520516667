






















































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
/* import { Container, Draggable } from "vue-smooth-dnd"; */
/* import LeadDashboardGroup from "./LeadDasboardGroup.vue"; */
import rules from "@/components/account/rules";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import SendEmail from "@/components/emails/SendEmail.vue";
import TableLeads from "@/views/Configurations/components/TableLeads.vue";
import LeadEdit from "@/views/Configurations/components/LeadEdit.vue";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import TableCalls from "@/views/Configurations/components/TableCalls.vue";
import BtnMakeCall from "@/views/CallCenter/buttons/BtnMakeCall.vue";
import AsignTo from "@/views/Configurations/components/AsignTo.vue";
import DrawerSms from "@/views/SMS/components/DrawerSms.vue";
import { Lead, LeadStatus } from "@/models/Lead";
import formatPhone from "@/utils/formatPhone";
import { getAPI } from "@/api/axios-base";
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import { debounce } from "lodash";
import moment from "moment";
import AllImages from "@/components/Images/AllImages.vue";
import cleanObject from "@/utils/cleanObject";

export default Vue.extend({
  components: {
    /*  LeadDashboardGroup, */
    /* Draggable, */
    /*  Container, */
    SendEmail,
    MaDatePicker,
    DrawerSms,
    TableLeads,
    TableCalls,
    BtnMakeCall,
    AsignTo,
    AllImages,
    LeadEdit,
  },
  data: () => ({
    loadingAcciones: false,
    deleteL: {
      deleteItem: {},
      deleteDialog: false,
    },
    showcalendar: false,
    changed: false,
    formValid: false,
    gender: "male",
    evaluation: false,
    tabSelect: null,
    title: "",
    footerProps: { "items-per-page-options": [15, 100, 500, 1000] },
    details: {},

    rules: {
      email: rules.email,
      required: rules.required,
      phone: rules.isPhone,
      maxCharsfull: (v: string) =>
        v.length >= 3 || (this as any).$t("min3Chars"),
    },
    options: {},
    dialogNote: false,
    dialogLead: false,
    selected: [],
    detailsnote: "",
    loadingProcedures: false,
    treatments: [],
    surgerys: [],
    typeProcedure: "Surgery",
    procedureInterest: null,
    othersubstances: false,
    phonelead: "",
    maillead: "",
    uuidexist: null,
    newlead: {
      howKnow: "",
      name: "",
      last_name: "",
      phone: "",
      email: "",
      weight: 0,
      height: 0,
      isMale: false,
      birthDate: "",
      medicalHistory: {
        surgeryTypeId: null,
        treatmentTypeId: null,
        doctorChoiceUuid: null,
        smoking: false,
        alcohol: false,
        otherSubstances: "",
        medicalCondition: "",
        alergies: "",
        previosSurgery: "",
        currentMedications: "",
        details: "",
      },
    },
    dateTo: "",
    dateFrom: "",
    uuidLead: "",
    body: {
      query: null,
      dates: null,
      status: null,
      noCoordinator: false,
      coordinatorUuid: null,
      interval: {
        offset: 10,
        limit: 0,
      },
    },
    upperDropPlaceholderOptions: {
      className: "cards-drop-preview",
      animationDuration: "150",
      showOnTop: true,
    },
    dropPlaceholderOptions: {
      className: "drop-preview",
      animationDuration: "150",
      showOnTop: true,
    },
    expanded: [],
    status: [
      { name: "Callback", value: "Callback" },
      { name: "Text", value: "Text" },
      { name: "Interested", value: "Interested" },
      { name: "Not Interested", value: "NotInterested" },
      { name: "Wrong Number", value: "WrongNumber" },
      { name: "Pending", value: "Pending" },
    ],

    cards: [
      {
        id: "NEW",
        title: "New",
        color: "#ab47bc",
        children: [],
      },
      {
        id: "EVALUATION",
        title: "Evaluation",
        color: "#ec407a",
        children: [],
      },
      {
        id: "NO_INTERESTED",
        title: "Not interested",
        color: "#e91e63",
        children: [],
      },
      {
        id: "PENDING",
        title: "Pending",
        color: "#f44336",
        children: [],
      },
    ],
  }),
  computed: {
    ...mapGetters(["getCoordinatorN", "getpatientN"]),
    ...mapState("crmConfigurationsModule", ["leads", "loading", "totalleads"]),
    ...mapState("crmSettingsModule", ["loadingRefer", "references"]),
    ...mapState("crmEmployeeModule", ["coordinators", "doctors"]),
    ...mapGetters(["isAdmin", "isSuper"]),
    ...mapState("crmCallCenterModule", ["callsLead"]),
    ...mapState("crmMedicFormModule", ["patients"]),
    /*  dateRangeText() {
      let temp = (this as any).body.dates;
      if ((this as any).body.dates.length > 1) {
        const val1 = (this as any).body.dates[0].split("-").splice(2, 1);
        const val2 = (this as any).body.dates[1].split("-").splice(2, 1);
        if (Number(val1) > Number(val2)) {
          temp = [];
          temp.push((this as any).body.dates[1]);
          temp.push((this as any).body.dates[0]);
        }
      }
      return temp.join(" ~ ");
    }, */

    Createvalid() {
      if (
        this.newlead.name != "" &&
        this.newlead.last_name != "" &&
        this.newlead.email != "" &&
        this.newlead.phone != "" &&
        this.newlead.howKnow != "" &&
        this.newlead.phone.length == 10
      ) {
        return true;
      }
      return false;
    },
    height() {
      const style = {
        height: `${
          this.$vuetify.breakpoint.height -
          64 * 3 -
          (this.$vuetify.breakpoint.lgAndUp ? 25 : 15)
        }px !important`,
      };

      return style;
    },

    headers() {
      return [
        {
          text: "NAME",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "EMAIL", value: "email" },
        { text: "SOURCE", value: "howKnow" },
        { text: "PHONE", value: "phone" },
        { text: this.getCoordinatorN, value: "coordinator" },
        { text: "STATUS", value: "status" },
        { text: "CALLS", value: "callCount", align: "center" },
        { text: "SMS", value: "smsCount" },
        { text: "", value: "actions" },
        { text: "", value: "data-table-expand" },
      ];
    },
    types() {
      return [
        { label: "Surgery", value: "Surgery" },
        { label: "Treatment", value: "Treatment" },
      ];
    },
    subtypes() {
      switch ((this as any).typeProcedure) {
        case "Treatment":
          return this.treatments;
        default:
          return this.surgerys;
      }
    },

    bmi() {
      let inches = 0;
      let feets = 0;
      if ((this as any).expanded.length == 0) {
        return 0;
      }
      if (
        (this as any).expanded[0].weight == 0 &&
        (this as any).expanded[0].height == 0
      )
        return 0;
      const he = (this as any).expanded[0].height.toString();
      if (he.includes(".")) {
        inches = Number(he.split(".")[1]);
        feets = Number(he.split(".")[0]);
      }
      if (he.includes(",")) {
        inches = Number(he.split(",")[1]);
        feets = Number(he.split(",")[0]);
      }

      const inchtometer = inches * 0.0254;
      const feettometer = feets * 0.3048;
      const bmi =
        (Number((this as any).expanded[0].weight) * 0.453592) /
        Math.pow(feettometer + inchtometer, 2);

      return Number(bmi).toFixed(2);
    },

    typebmi() {
      const bmitemp = Number(this.bmi);

      if (bmitemp == 0) {
        return "No BMI";
      }
      if (bmitemp <= 18.5) {
        return "Underweight";
      }
      if (bmitemp > 18.5 && bmitemp <= 24.9) {
        return "Normal weight";
      }
      if (bmitemp >= 25 && bmitemp <= 29.9) {
        return "Overweight";
      }

      return "Obesity";
    },
    colorWeight() {
      if (this.typebmi == "No BMI") {
        return "grey";
      }
      if (this.typebmi == "Underweight") {
        return "amber";
      }
      if (this.typebmi == "Normal weight") {
        return "green";
      }
      if (this.typebmi == "Overweight") {
        return "orange";
      }
      if (this.typebmi == "Obesity") {
        return "red";
      }
    },
  },
  async mounted() {
    (this as any).actListReferences();
    (this as any).getTreatments();
    (this as any).getSurgeries();
    (this as any).actGetPatientDoctors();
    (this as any).actGetCoordinators();
  },
  watch: {
    expanded(val) {
      if (val != []) {
        (this as any).expandedLead();
      }
    },
    options(val) {
      if (val != {}) {
        (this as any).getLeads();
      }
    },
    dateFrom(val) {
      if (val !== null && this.dateTo !== null && this.dateTo !== "") {
        (this as any).getLeads();
      }
    },
    dateTo(val) {
      if (val !== null && this.dateFrom !== null && this.dateFrom !== "") {
        (this as any).getLeads();
      }
    },
    procedureInterest(val) {
      if (val != null) {
        if (this.typeProcedure == "Surgery") {
          this.newlead.medicalHistory.surgeryTypeId = val;
        } else {
          this.newlead.medicalHistory.treatmentTypeId = val;
        }
      }
    },

    phonelead: {
      async handler() {
        this.newlead.phone = this.phonelead;
        if (this.phonelead.length == 10) {
          await (this as any).actFilterPatient({
            query: this.phonelead,
            range: { limit: 100, offset: 0 },
          });
          if (this.patients.length != 0) {
            this.uuidexist = this.patients[0].uuid;
          } else {
            this.uuidexist = null;
          }
        } else if (this.phonelead.length == 0) {
          this.uuidexist = null;
        }
      },
    },
    maillead: {
      async handler() {
        this.newlead.email = this.maillead;
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (pattern.test(this.maillead)) {
          await (this as any).actFilterPatient({
            query: this.maillead,
            range: { limit: 100, offset: 0 },
          });
          if (this.patients.length != 0) {
            this.uuidexist = this.patients[0].uuid;
          } else {
            this.uuidexist = null;
          }
        } else {
          this.uuidexist = null;
        }
      },
    },
  },
  methods: {
    ...mapActions("crmSettingsModule", ["actListReferences"]),
    ...mapActions("crmConfigurationsModule", [
      "actGetLeads",
      "actConverToPatient",
      "actAddNote",
      "actAddLead",
    ]),
    ...mapActions("crmMedicFormModule", ["actFilterPatient"]),
    ...mapActions("crmEmployeeModule", [
      "actGetCoordinators",
      "actGetPatientDoctors",
    ]),
    ...mapActions("crmCallCenterModule", ["actFilterCalls"]),
    ...mapMutations("crmConfigurationsModule", ["mutleadSelect"]),
    statusShow(item: Lead) {
      if (item.status == null || item.status == undefined) {
        return "-";
      }
      switch (item.status) {
        case LeadStatus.NotInterested:
          return "Not Interested";
        case LeadStatus.WrongNumber:
          return "Wrong Number";
        default:
          return item.status;
      }
    },
    getTreatments() {
      this.loadingProcedures = true;
      getAPI("/sprocedures/listNom/Treatment").then((res) => {
        this.treatments = res.data;
        this.loadingProcedures = false;
      });
    },

    getSurgeries() {
      this.loadingProcedures = true;
      getAPI("/sprocedures/listNom/Surgery").then((res) => {
        this.surgerys = res.data;
        this.loadingProcedures = false;
      });
    },

    convertToPatient(lead: any) {
      (this as any).mutleadSelect(lead);
      this.$router.push("/patients/create");
    },
    async expandedLead() {
      if ((this as any).expanded.length != 0) {
        this.uuidLead = (this as any).expanded[0].uuid;
        this.details = (this as any).expanded[0];
        const phone = (this as any).expanded[0].phone;
        await (this as any).actFilterCalls({
          whateverNumber: phone,
          limit: 500,
          offset: 0,
        });
      } else {
        this.uuidLead = "";
        this.details = {};
      }
    },

    toIsoString(values: string[]) {
      let temp = [];
      if (values.length == 1 || values[0] == values[1]) {
        const date1 = new Date(values[0]);
        const date2 = new Date(values[0]);
        date2.setUTCHours(23, 59);
        temp.push(date1.toISOString());
        temp.push(date2.toISOString());
      } else {
        values.forEach((val) => {
          const date = new Date(val);
          temp.push(date.toISOString());
        });
      }

      return temp;
    },

    toDelete(item: any) {
      this.deleteL.deleteDialog = true;
      this.deleteL.deleteItem = item;
    },
    cancelDelete() {
      this.deleteL = { deleteItem: {}, deleteDialog: false };
    },
    confirmDelte() {
      (this as any).loadingAcciones = true;
      getAPI
        .delete("/social/delete/" + (this as any).deleteL.deleteItem.uuid)
        .then(() => {
          notifyInfo("Lead has been deleted");
          (this as any).loadingAcciones = false;
          (this as any).cancelDelete();
          (this as any).getLeads();
        })
        .catch((error: any) => {
          (this as any).loadingAcciones = false;
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    getLeads() {
      this.selected = [];
      let reqbody: any = {};

      reqbody = {
        query: this.body.query,
        status: this.body.status,
        noCoordinator: this.body.noCoordinator,
        coordinatorUuid: this.body.coordinatorUuid,
        interval: this.body.interval,
      };
      if (
        (this.dateTo != "" &&
          this.dateTo != null &&
          this.dateTo != undefined &&
          this.dateFrom != "" &&
          this.dateFrom != null &&
          this.dateFrom != undefined) ||
        this.body.dates != null
      ) {
        if (this.body.dates != null) {
          reqbody = { ...reqbody, dateInterval: this.body.dates };
        } else {
          reqbody = {
            ...reqbody,
            dateInterval: {
              date1: moment(this.dateFrom).startOf("day").utc().toISOString(),
              date2: moment(this.dateTo).endOf("day").utc().toISOString(),
            },
          };
        }
      }
      const { page, itemsPerPage }: any = this.options;
      const itmper = itemsPerPage != undefined ? itemsPerPage : 15;
      const p = page != undefined ? page : 1;
      const reqrange = {
        limit: itmper,
        offset: (p - 1) * itmper,
      };
      reqbody.interval = reqrange;
      this.body = {
        query: reqbody.query,
        status: reqbody.status,
        noCoordinator: reqbody.noCoordinator,
        interval: reqbody.interval,
        dates: reqbody.dateInterval ? reqbody.dateInterval : null,
        coordinatorUuid: reqbody.coordinatorUuid,
      };
      reqbody = (this as any).cleanNull(reqbody);
      (this as any).actGetLeads(reqbody);
    },

    formatDate(date: any) {
      const d = moment(date).format("MM/DD/YYYY");
      return d;
    },
    getHeight(height: any) {
      if (height == undefined || height == null || height == "") {
        return 0;
      }
      const ht = height.toString();
      if (ht.includes(".")) {
        return ht.split(".")[0] + " ft, " + ht.split(".")[1] + " in";
      } else if (ht.includes(",")) {
        return ht.split(",")[0] + " ft, " + ht.split(",")[1] + " in";
      } else {
        return ht + " ft";
      }
    },

    getDoctorName(item: any) {
      if (item.medicalHistory.doctorChoice != null) {
        return item.medicalHistory.doctorChoice.fullname;
      }
      return "None";
    },
    getProcedureName(item: any) {
      if (item.medicalHistory.surgeryType != null) {
        return item.medicalHistory.surgeryType.name;
      }
      if (item.medicalHistory.treatmentType != null) {
        return item.medicalHistory.treatmentType.name;
      }
      return "None";
    },
    /*  formatTime(date: any) {
      if (!date) return null;
      const [fecha, hora] = date.split("T");
      let [hours, minutes, seconds] = hora.split(":");
      seconds = seconds.substr(-20, 2);
      return `${hours}:${minutes}:${seconds}`;
    }, */
    getCardPayload(columnId: any) {
      return (index: any) => {
        return this.cards.filter((p) => p.id === columnId)[0].children[index];
      };
    },

    showDetails(patient: any) {
      this.$router.push(`/patients/details/${patient.uuid}`);
    },

    OpenDialog(type: any, uuid: string) {
      switch (type) {
        case "note":
          this.dialogNote = true;
          break;
        default:
          this.dialogLead = true;
          break;
      }
      this.uuidLead = uuid ? uuid : "none";
    },

    updateFiles() {
      setTimeout(() => {
        (this as any).$refs.images.getImages();
      }, 500);
    },

    async addNote() {
      await (this as any).actAddNote({
        details: this.detailsnote,
        leadUuid: this.uuidLead,
        type: this.evaluation ? "EVALUATION" : "ANY",
      });
      (this as any).cancelDialog();
      (this as any).getLeads();
    },
    async addLead() {
      let body = Object.assign({}, this.newlead);
      body.phone = "+1" + body.phone;
      body.weight = Number(body.weight);
      body.height = Number(body.height);

      body = (this as any).cleanNull(body);
      body.medicalHistory = (this as any).cleanNull(body.medicalHistory);
      if (body.birthDate) {
        body.birthDate = moment(body.birthDate).utc().toISOString();
      }
      await (this as any).actAddLead(body);
      (this as any).cancelDialog();
      (this as any).getLeads();
    },

    cleanNull(obj: any) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },
    gotoPatient() {
      this.$router.push(`/patients/details/${this.uuidexist}`);
    },

    cancelDialog() {
      this.dialogNote = false;
      this.dialogLead = false;
      this.evaluation = false;
      (this as any).descriptioncall = "";
      this.detailsnote = "";
      (this.othersubstances = false),
        (this.newlead = {
          isMale: false,
          name: "",
          howKnow: "",
          last_name: "",
          phone: "",
          email: "",
          height: 0,
          weight: 0,
          birthDate: "",
          medicalHistory: {
            surgeryTypeId: null,
            treatmentTypeId: null,
            doctorChoiceUuid: null,
            alcohol: false,
            smoking: false,
            otherSubstances: "",
            medicalCondition: "",
            alergies: "",
            previosSurgery: "",
            currentMedications: "",
            details: "",
          },
        });
    },

    refresh(type: string) {
      switch (type) {
        case "dates":
          this.dateTo = "";
          this.dateFrom = "";
          this.body.dates = null;
          break;
        default:
          this.body.query = null;
          break;
      }
      (this as any).getLeads();
    },
  },
});
