export interface Lead {
  campaing?: string;
  status?: LeadStatus;
  convertedPatient?: boolean;
  howKnow?: string;
  coordinator: {
    fullname?: string;
    uuid: string;
  };
  createAt?: string;
  created_time?: string;
  email: string;
  id?: string;
  last_name: string;
  name: string;
  notes?: [];
  patient?: null;
  phone: string;
  uuid?: string;
}

export enum LeadStatus {
  Callback = "Callback",
  Text = "Text",
  Interested = "Interested",
  NotInterested = "NotInterested",
  WrongNumber = "WrongNumber",
  Pending = "Pending",
}
