<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon @click="dialog = true">
          <v-icon color="white">mdi-email </v-icon>
        </v-btn>
      </template>
      <span>Send Email</span>
    </v-tooltip>
    <v-dialog
      v-model="dialog"
      persistent
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="blue darken-1 white--text" primary-title>
          Send email to:
        </v-card-title>
        <v-card-subtitle class="blue darken-1 white--text d-flex flex-column">
          <span>Name: {{ fullname }}</span>
          <span>Email: {{ user.email }}</span>
        </v-card-subtitle>
        <v-card-text>
          <div>
            <v-switch inset v-model="adjunct" label="Adjunct files"></v-switch>
            <template v-if="adjunct">
              <v-file-input
                v-model="files"
                placeholder="Upload your documents"
                label="Files"
                multiple
                counter
                show-size
                prepend-icon="mdi-paperclip"
              >
                <template v-slot:selection="{ text }">
                  <v-chip small label color="primary">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </template>
          </div>
          <v-text-field
            v-model="subject"
            outlined
            dense
            label="Subject"
          ></v-text-field>
          <v-textarea
            outlined
            name="input-7-4"
            label="Text"
            v-model="body"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="cancelSend">Cancel</v-btn>
          <v-btn
            :disabled="invalidSend || loading"
            text
            color="primary"
            @click="confirmSend"
            >Send</v-btn
          >
        </v-card-actions>
        <v-overlay absolute :value="loading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { apiBaseUrl } from "@/enviorment";
import { getAPI } from "@/api/axios-base";
import { notifyError } from "../Notification";
export default {
  name: "email-send",
  props: {
    user: Object,
  },
  data() {
    return {
      dialog: false,
      files: [],
      filesuuid: [],
      loading: false,
      body: "",
      adjunct: false,
      subject: "",
    };
  },
  computed: {
    fullname() {
      if (this.user == undefined || this.user == null) {
        return "";
      }
      if (this.user.name == undefined) {
        return this.user.fullname;
      }
      return this.user.name + " " + this.user.last_name;
    },
    invalidSend() {
      if (
        this.adjunct == true &&
        (this.files.length == 0 || this.body == "" || this.subject == "")
      ) {
        return true;
      }
      if (this.body == "" || this.subject == "") {
        return true;
      }
      return false;
    },
  },
  watch: {
    adjunct(val) {
      if (!val) {
        this.files = [];
      }
    },
    filesuuid(val) {
      if (val.length != 0 && val.length == this.files.length) {
        this.sendEmail();
      }
    },
  },
  methods: {
    async confirmSend() {
      this.loading = true;
      if (this.adjunct) {
        await this.uploadImages();
      } else {
        this.sendEmail();
      }
    },
    sendEmail() {
      let bodyreq = {
        email: this.user.email,
        subject: this.subject,
        body: this.body,
      };
      if (this.adjunct) {
        bodyreq = { ...bodyreq, filesUuid: this.filesuuid };
      }
      getAPI
        .post(apiBaseUrl + "/social/sendEmail", bodyreq)
        .then((res) => {
          this.cancelSend();
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    cancelSend() {
      this.dialog = false;
      this.files = [];
      this.filesuuid = [];
      this.body = "";
      this.adjunct = false;
      this.subject = "";
      this.loading = false;
    },
    async uploadImages() {
      this.files.forEach(async (element) => {
        const formData = new FormData();
        formData.append("file", element);
        try {
          const res = (
            await getAPI.post(
              apiBaseUrl + "/social/uploadAttachmentFiles",
              formData
            )
          ).data;

          this.filesuuid.push(res.uuid);
        } catch (error) {
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        }
      });
    },
  },
};
</script>
<style lang=""></style>
