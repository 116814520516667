


































import Vue from "vue";
import { mapActions, mapState } from "vuex";
export default Vue.extend({
  name: "crm-leads-component",
  data() {
    return {
      tempLead: null, 
    };
  },
  props: {
    data: Array,
    headers: String,
    
  },
  computed: {
    ...mapState("crmConfigurationsModule", ["loading"]),
  },
  methods:{
    ...mapActions("crmConfigurationsModule", ["actAddDescription"]),
      formatDate(date:any) {
      if (!date) return null;
      const [year, month, data] = date.split("-");
      const day = data.substr(-20, 2);
      return `${day}/${month}/${year}`;
    },
  }
});
